"use client";
import { useSession } from "@clerk/nextjs";
import { CloseOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, ButtonBase, ClickAwayListener, Collapse, Grid2 as Grid, IconButton, Typography, styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Link from "next/link";
import { useState } from "react";
import { MobileActions } from "../styles/styledComponents";
import BottomNav from "./BottomNavBar";
import { useGetPrivateMemberBreederEndpoint, useGetPrivateMemberDetailsEndpoint } from "./generated/happydogsComponents";
import HorizontalMenu from "./MyProfile/HorizontalMenu";
import NotificationMenu from "./NotificationDropdown";
import ProfileDropdown from "./ProfileDropdown";
import SearchBar from "./Searchbar";
import { orange, tan } from "./Theme";

const StyledAppBar = styled(AppBar)`
  background-color: ${orange[15]};
  box-shadow: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: auto;

  @media screen and (max-width: 600px) {
    height: 50px;
    overflow: hidden;
  }
  @media screen and (max-width: 900px) {
    height: 70px;
    overflow: hidden;
  }
`;

const ResponsiveAppBar = () => {
  const { isSignedIn, isLoaded } = useSession();

  const { data: member } = useGetPrivateMemberDetailsEndpoint({}, { enabled: isSignedIn });
  const isBreeder = member?.roles.includes("Breeder") ?? false;
  const isVerified = member?.roles.includes("Verified") ?? false;

  const { data: breeder } = useGetPrivateMemberBreederEndpoint(
    { pathParams: { memberId: member?.id! } },
    { enabled: isBreeder && !!member?.id },
  );
  const hasBreederPage = !!breeder;

  const [showSearchBar, setShowSearchBar] = useState(false);

  return (
    <>
      <ClickAwayListener onClickAway={() => setShowSearchBar(false)}>
        <StyledAppBar position="fixed">
          <Collapse in={showSearchBar} collapsedSize="100px" sx={{ width: "100%", height: { xs: "50px" } }}>
            <Box width="100%" sx={{ display: "flex", height: "140px" }} pl={{ md: "30px", xs: "10px" }} pr={{ md: "30px", xs: "10px" }}>
              {isLoaded && (
                <>
                  <Grid
                    container
                    columns={12}
                    sx={{
                      display: { md: "flex", xs: "none" },
                      alignItems: "start",
                      width: "100%",
                      paddingTop: "20px",
                    }}
                  >
                    <Grid size={2}>
                      <Link href="/" style={{ alignSelf: "start" }}>
                        <img alt={"happydogs-logo"} src="/assets/icons/logoOrange.svg" width={100} />
                      </Link>
                    </Grid>
                    <Grid size={8}>
                      <Box display="flex" flexDirection="column" gap={showSearchBar ? "0px" : "0px"} sx={{ width: "100%" }}>
                        <Collapse
                          unmountOnExit
                          in={showSearchBar}
                          collapsedSize={0}
                          sx={{
                            width: "100%",
                            alignSelf: "center",
                            display: "flex",
                          }}
                        >
                          <Box
                            alignSelf={"center"}
                            justifySelf={"center"}
                            justifyContent={"center"}
                            alignContent={"center"}
                            display={"flex"}
                            width={"100%"}
                          >
                            <Box width={"100%"} maxWidth={{ lg: "600px", md: "400px" }}>
                              <SearchBar focus={showSearchBar} />
                            </Box>
                          </Box>
                        </Collapse>
                        <HorizontalMenu isBreeder={isBreeder} hasBreederPage={hasBreederPage} />
                      </Box>
                    </Grid>
                    <Grid size={2}>
                      {isSignedIn ? (
                        <Box display={"flex"} gap={{ lg: "15px", md: "8px" }} justifyContent="end">
                          <IconButton sx={{ padding: 0 }} onClick={() => setShowSearchBar(!showSearchBar)}>
                            {showSearchBar ? (
                              <CloseOutlined color="secondary" sx={{ fontSize: { md: "30px", lg: "40px" } }} />
                            ) : (
                              <SearchIcon color="secondary" sx={{ fontSize: { md: "30px", lg: "40px" } }} />
                            )}
                          </IconButton>

                          <NotificationMenu memberId={member?.id} />

                          <ProfileDropdown />
                        </Box>
                      ) : (
                        <Box display={"flex"} width={"fit-content"}>
                          <ButtonBase
                            LinkComponent={Link}
                            href="/login"
                            sx={{
                              justifySelf: "end",
                              textDecoration: "underline",
                              color: tan[300],
                              pl: "8px",
                              pr: "8px",
                              borderRadius: "40px",
                              mr: { xs: "8px", xl: "25px" },
                            }}
                          >
                            <Typography variant="subtitle2" whiteSpace={"nowrap"}>
                              Logg inn
                            </Typography>
                          </ButtonBase>
                          <Button
                            color="primary"
                            variant="contained"
                            LinkComponent={Link}
                            href="/register"
                            sx={{ justifySelf: "end", display: { xs: "none", md: "inline-block" } }}
                          >
                            Registrer deg
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Collapse
                    in={showSearchBar}
                    collapsedSize="70px"
                    sx={{ display: { md: "none" }, width: "100%", height: { xs: "50px", sm: "80px" }, paddingTop: "10px" }}
                  >
                    <Collapse unmountOnExit in={showSearchBar} collapsedSize={0} sx={{ width: "100%" }}>
                      <Box display={"flex"} width={"100%"}>
                        <SearchBar focus={showSearchBar} />{" "}
                        <ButtonBase onClick={() => setShowSearchBar(!showSearchBar)}>
                          <CloseOutlined color="secondary" fontSize="large" />
                        </ButtonBase>
                      </Box>
                    </Collapse>

                    <Collapse in={!showSearchBar}>
                      <MobileActions>
                        <Link href="/" style={{ alignSelf: "center" }}>
                          <img alt={"happydogs-logo"} src="/assets/icons/logoOrange.svg" width={70} />
                        </Link>

                        {isSignedIn ? (
                          <Box display="flex" gap="5px">
                            <Box display="flex">
                              <IconButton sx={{ padding: 0 }} onClick={() => setShowSearchBar(!showSearchBar)}>
                                {showSearchBar ? (
                                  <>
                                    <CloseOutlined color="secondary" sx={{ fontSize: { sm: "30px", md: "40px" } }} />
                                  </>
                                ) : (
                                  <SearchIcon color="secondary" sx={{ fontSize: { sm: "30px", md: "40px" } }} />
                                )}
                              </IconButton>

                              <NotificationMenu memberId={member?.id} />
                            </Box>
                            <ProfileDropdown />
                          </Box>
                        ) : (
                          <Box display="flex" sx={{ justifySelf: "end" }}>
                            <ButtonBase
                              LinkComponent={Link}
                              href="/login"
                              sx={{
                                justifySelf: "end",
                                textDecoration: "underline",
                                color: tan[300],
                                pl: "8px",
                                pr: "8px",
                                borderRadius: "40px",
                                mr: { md: "8px", lg: "25px" },
                              }}
                            >
                              <Typography variant="subtitle2" whiteSpace={"nowrap"}>
                                Logg inn
                              </Typography>
                            </ButtonBase>
                            <Button variant="contained" color="primary" LinkComponent={Link} href="/register">
                              Registrer
                            </Button>
                          </Box>
                        )}
                      </MobileActions>
                    </Collapse>
                  </Collapse>
                  <BottomNav isBreeder={isBreeder} hasBreederPage={hasBreederPage} />
                </>
              )}
            </Box>
          </Collapse>
        </StyledAppBar>
      </ClickAwayListener>
    </>
  );
};
export default ResponsiveAppBar;
