"use client";
import { useUser } from "@clerk/nextjs";
import { SupportAgent } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { usePathname } from "next/navigation";
import { useState } from "react";
import FeedBackDrawer from "./FeedBackDrawer";

const ReportButton = () => {
  const { user } = useUser();
  const roles = user?.publicMetadata.roles as string[];
  const [anchorEl, setAnchorEl] = useState(null);
  const pathname = usePathname();

  if (pathname.includes("messages")) return;

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      {!!user && (
        <Fab
          color="primary"
          onClick={handleClick}
          sx={{
            alignSelf: "flex-end",
            position: "fixed",
            bottom: { md: "8vh", xs: "15vh", sm: "10vh" },
            right: { md: "15vh", xs: "3vh", sm: "5vh" },
            width: "fit-content",
            height: "fit-content",
          }}
        >
          <SupportAgent sx={{ fontSize: { md: "70px", xs: "50px" }, padding: "5px" }} />
        </Fab>
      )}
      <FeedBackDrawer open={!!anchorEl} setOpen={handleClick} />
    </>
  );
};

export default ReportButton;
