"use client";

import { CookieOutlined } from "@mui/icons-material";
import { Box, Button, ButtonBase, Fab, FormControlLabel, Switch, Typography, styled } from "@mui/material";
import { clarity } from "clarity-js";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { useState } from "react";
import { claritySettings } from "../app/clarity";
import CookieInfoModal from "./Dialog/CookieInfoModal";
import { orange, tan } from "./Theme";

const CookieBar = () => {
  const consentGranted = getCookie("consentGranted");
  const [showConsent, setShowConsent] = useState(!consentGranted);
  const [acceptAnalytics, setAcceptAnalytics] = useState(!(consentGranted === "false"));
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const declineConsent = () => {
    deleteCookie("_clsk");
    deleteCookie("_clck");
    clarity.stop();
    window.setTimeout(() => clarity!.start(claritySettings), 250);
  };
  const declineCookie = () => {
    setCookie("consentGranted", "false", {});
    declineConsent();
    setShowConsent(false);
    setOpenInfoModal(false);
  };

  const acceptSelection = () => {
    if (acceptAnalytics) {
      clarity.consent();
      setCookie("consentGranted", "true", {});
      setOpenInfoModal(false);
    } else {
      declineConsent();
      setCookie("consentGranted", "false", {});
    }
    setShowConsent(false);
  };

  const acceptCookie = () => {
    setShowConsent(false);
    clarity.consent();
    setCookie("consentGranted", "true", {});
    setOpenInfoModal(false);
  };

  if (!showConsent)
    return (
      <FloatingCookieButton
        onClick={() => {
          setShowConsent(true);
        }}
      >
        <CookieOutlined />
      </FloatingCookieButton>
    );

  return (
    <StyledCookieBarSection>
      <StyledBox>
        <StyledImage src="/assets/icons/logoOrange.svg" width={150} />
        <Box alignItems={"center"}>
          <Typography>
            HappyDogs bruker valgfrie informasjonskapsler til å forbedre opplevelsen på nettsiden. Ved å trykke på "tillatt" godtar du bruk
            av av informasjonskapsler.{" "}
            <ButtonBase onClick={() => setOpenInfoModal(true)}>
              <Typography sx={{ textDecoration: "underline" }} color="primary">
                Les mer.
              </Typography>
            </ButtonBase>
          </Typography>
          <Box>
            <FormControlLabel control={<Switch disabled checked />} label="Nødvendige" />
            <FormControlLabel
              control={<Switch checked={acceptAnalytics} />}
              onChange={() => setAcceptAnalytics(!acceptAnalytics)}
              label="Analyse"
            />
          </Box>
        </Box>
        <Box
          width={{ md: "20%", xs: "fit-content" }}
          marginLeft="20px"
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          alignContent={"center"}
          alignSelf={"center"}
        >
          <Button variant="contained" onClick={acceptCookie} sx={{ whiteSpace: "nowrap", width: "100%" }}>
            Tillatt alle
          </Button>
          <Box>
            <Button color="secondary" onClick={acceptSelection} sx={{ whiteSpace: "nowrap" }}>
              Tillatt valgte
            </Button>
            <Button color="secondary" onClick={declineCookie} sx={{ whiteSpace: "nowrap" }}>
              Avslå
            </Button>
          </Box>
        </Box>
      </StyledBox>
      <CookieInfoModal
        open={openInfoModal}
        acceptAnalytics={acceptAnalytics}
        setAcceptAnalytics={setAcceptAnalytics}
        acceptAll={acceptCookie}
        acceptSelected={acceptSelection}
        decline={declineCookie}
        setOpenInfoModal={setOpenInfoModal}
      />
    </StyledCookieBarSection>
  );
};
export default CookieBar;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  padding: 10px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: start;
  }
`;

const StyledCookieBarSection = styled(Box)`
  position: fixed;
  bottom: 6vh;
  left: 50%;
  background: ${orange[15]};
  padding: 10px;
  border-radius: 10px;
  justify-self: center;
  z-index: 5000;
  width: 90%;
  margin-left: auto;
  max-width: 1200px;
  transform: translateX(-50%);

  border: 1px ridge ${tan[300]};
  align-content: center;
  @media screen and (max-width: 1000px) {
    bottom: 10vh;
    padding: 20px;
  }
  @media screen and (max-width: 800px) {
    height: fit-content;
    bottom: 16vh;
  }
`;

const StyledImage = styled("img")`
  width: 157px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const FloatingCookieButton = styled(Fab)`
  align-self: flex-end;
  position: fixed;
  bottom: 1%;
  left: 1%;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
